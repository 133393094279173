<!-- eslint-disable import/no-cycle -->
<template>
  <section>
    <b-card>
      <h1>Statistiques sur les établissements de restauration</h1>
      <div
        class="mt-2"
      >
        <h3>Filtres de recherche</h3>
        <StatisticsFilters
          v-model="filtersForm"
          :active-filters="activeFilters"
          :is-loading="isLoadingTableData"
          @submit="getStatistics"
        />

      </div>
    </b-card>

    <b-row class="match-height">
      <!-- <b-col lg="6">
        <StatsEtablissementParService />
      </b-col> -->
      <b-col lg="12">
        <StatsEtablissementParLocalisation
          :table-datas="statsData.nombreDePlaceDisponible.tableDatas"
          :is-loading-table-data="isLoadingTableData"
        />
      </b-col>

    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BCardText,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import utilsService from '@/services/utils/utils.service'
import StatisticsFilters from '@/components/StatisticsFilters.vue'

// store modules and vuex utilities
// eslint-disable-next-line import/no-cycle
import etablissementsStroreModule from '@/store/etablissements'
// eslint-disable-next-line import/no-cycle
import paramsStoreModule from '@/store/params'
// eslint-disable-next-line import/no-cycle
import statisticsStoreModule from '@/store/statistics'
// eslint-disable-next-line import/no-cycle
import formulaireStoreModule from '@/store/formulaire'

// eslint-disable-next-line import/no-cycle
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'
import { mapActions, mapState } from 'vuex'
import { buildFiltersForRequest } from '@/helpers/http-utils'
import StatsEtablissementParLocalisation from './StatsEtablissementParLocalisation.vue'

const activeFilters = [
  'periode',
]

const statsData = {
  nombreDePlaceDisponible: {
    tableDatas: {
      departements: [],
      communes: [],
      arrondissements: [],
      villages: [],
    },
  },

}

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BCardText,
    StatisticsFilters,
    BButton,
    BSpinner,
    StatsEtablissementParLocalisation,
  },
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'params', module: paramsStoreModule },
      { path: 'statistics', module: statisticsStoreModule },
      { path: 'formulaire', module: formulaireStoreModule },

    ]

    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },
  data() {
    return {
      isLoadingTableData: false,

      isLoading: false,
      activeFilters,
      filtersForm: {},

      statsData,

    }
  },
  computed: {
    etablissementId() {
      return utilsService.etablissementId
    },
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },
  async mounted() {
    await this.getStatistics()
  },
  methods: {
    ...mapActions('statistics', {
      action_getStatsEtablissementsDeRestauration: 'getStatsEtablissementsDeRestauration',
    }),
    ...mapActions('formulaire', {
      fetchFormulaires: 'fetchFormulaires',
      exportFormulaires: 'exportFormulaires',
    }),
    ...mapActions('etablissements', {
      action_getListTypesEtablissement: 'getListTypesEtablissement',
    }),
    onPaginationChange(val) {
      // console.log('paginationChange', val)
      this.paginationData = { ...this.paginationData, ...val }
    },
    localFilter() {
      const query = this.localSearchQuery.toLowerCase()
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.tableDatas[
        this.selectedTypeLocalisation
      ].filter(e => {
        if (!query) return true
        return e.name.toLowerCase().includes(query)
      })
      this.filteredTableDatas[this.selectedTypeLocalisation] = this.filteredTableDatas[this.selectedTypeLocalisation].filter(e => true)
    },
    async getStatistics() {
      this.isLoadingTableData = true

      const periodeFilter = () => {
        const { from, to } = this.filtersForm.periode || {}
        if (!from && !to) return { createdAt: '' }
        const result = !to ? `${from},${from}` : `${from},${to}`
        return { createdAt: result }
      }

      const filters = {
        ...periodeFilter(),
      }

      delete filters.periode
      const processedFilters = buildFiltersForRequest(filters)
      const reqOptions = {
        params: {
          filter: processedFilters,
        },
      }
      await this.action_getStatsEtablissementsDeRestauration(reqOptions)
        .then(async res => {
          // console.log('res', res)

          // await this.getListTypesEtablissement()
          // const { data } = res
          // const { resources } = data
          // const { options } = resources
          const { statsEtabRestauPerLocation } = res || {}
          // console.log('statsEtabRestauPerLocation :>> ', statsEtabRestauPerLocation)

          const { tableDatas: nombreDePlaceDisponibleTableData } = this.statsData.nombreDePlaceDisponible
          statsEtabRestauPerLocation.forEach(localisationItem => {
            const { items, queryId: localisation } = localisationItem
            nombreDePlaceDisponibleTableData[`${localisation}s`] = items
          })

          this.statsData.nombreDePlaceDisponible.tableDatas = nombreDePlaceDisponibleTableData

          this.isLoadingTableData = false

          // console.log('stats', res)
        })
        .catch(error => {
          this.isLoadingTableData = false

          // console.log(error)
        })
    },

  },
  created() {},
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
